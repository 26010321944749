import React from "react";
import Box from "../../../ProductView/Common/Box/Box";
import Common from "../Common/Common";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {removeProductsByBrand, removeProductsByCategory} from "../../../../ActionCreators/Admin";

const RemoveProducts: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();

    return <Box title={"Usunięcie produktów"}>
        <Common
            texts={{
                byCategory: "Czy na pewno chcesz usunąć wszystkie produkty z tej kategorii?",
                byBrand: "Czy na pewno chcesz usunąć wszystkie produkty z tej marki?"
            }}
            actions={{
                byCategory: async (id) => await dispatch(removeProductsByCategory(id)),
                byBrand: async (id) => await dispatch(removeProductsByBrand(id))
                ,
            }}
        />
    </Box>
}

export default RemoveProducts;
