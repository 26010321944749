import {
    Product,
    ProductElement,
    ProductElementVariant,
    ProductElementVariantsCombination,
    ProductTemplate,
    TemplateGuideLine
} from "mesmetric-v2-common/models";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import _, {cloneDeep} from "lodash";
import AutoSaveManager from "../Helpers/AutoSaveManager";
import axios from "axios";
import {diff} from "deep-diff";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {updateProductInList} from "./Products";
import {LocalStorageKey} from "../Utils/StorageUtils";

export enum ActionTypes {
    setProductData = "setProductData",
    removeProductData = "removeProductData",
    updateValue = "updateValue",
    setAutoSave = "setAutoSave",
    saveInProgress = "saveInProgress",
    changesDetected = "changesDetected"
}

export interface SetProductDataAction {
    type: ActionTypes.setProductData
    productData: Product,
    fetchedProductData?: Product
}

export interface RemoveProductDataAction {
    type: ActionTypes.removeProductData
}

export interface SetAutoSaveAction {
    type: ActionTypes.setAutoSave,
    autoSaveEnabled: boolean
}

export interface SetChangesDetectedAction {
    type: ActionTypes.changesDetected,
    changesDetected: boolean
}

export interface UpdateValueAction {
    type: ActionTypes.updateValue,
    productData: Product
}

export interface SaveInProgressAction {
    type: ActionTypes.saveInProgress,
    saveInProgress: boolean
}

const SaveManager = new AutoSaveManager();
const GuidelinesSaveManager = new AutoSaveManager();

export const saveProduct = (): ThunkAction<void, AppState, {}, SetChangesDetectedAction | SaveInProgressAction> =>
    async (dispatch, getState) => {
        const guidelines = (getState().ProductData.productData?.primaryCategory.template as ProductTemplate)?.guidelines;
        const guidelinesChanged = diff(guidelines, (getState().ProductData.fetchedProductData?.primaryCategory?.template as ProductTemplate)?.guidelines);
        dispatch(saveInProgress(true));
        if (guidelinesChanged) {
            await dispatch(saveGuidelines(guidelines));
        }
        await dispatch(autoSaveReport());
    };


const FIELDS_TO_OMIT_WHEN_COMPARE_PRODUCT_DATA = ["price.calculated"];

const autoSaveEnabled = (): boolean => localStorage.getItem(LocalStorageKey.AUTO_SAVE_DISABLED_FOR_PRODUCTS) === "true";

const SALE_CATEGORY_NAME = "s a l e";

export const getCalculatedPrice = (primaryCategory: string, brandMultiplier: number, price: number, currencyValue: number, multiplier: number) => {
    const finalMultiplier = primaryCategory === SALE_CATEGORY_NAME ? 1 : brandMultiplier;
    return 5 * Math.round((price * currencyValue * multiplier * finalMultiplier) / 5);
};

const setCalculatedPrice = (product: Product) => {
    const price = _.get(product, "price.value", 0);
    const currencyValue = _.get(product, "price.currency.rate", 0);
    const multiplier = _.get(product, "price.type.multiplier", 1);
    const brandMultiplier = _.get(product, "brand.priceMultiplier", 1);
    const primaryCategory = _.get(product, "primaryCategory.name.pl", "");
    _.set(product, "price.calculated", getCalculatedPrice(primaryCategory, brandMultiplier, price, currencyValue, multiplier));
};

export const setProductData = (productData: Product, fetchedProductData?: Product, initial?: boolean): ThunkAction<void, AppState, {}, SetProductDataAction | SetAutoSaveAction> =>
    async (dispatch, getState) => {
        const autoSave = autoSaveEnabled();
        setCalculatedPrice(productData);
        if (autoSave) {
            dispatch({
                type: ActionTypes.setProductData,
                productData,
                fetchedProductData: fetchedProductData
            });
        } else {
            const storageKey = LocalStorageKey.PRODUCT(productData);
            const jsonData = localStorage.getItem(storageKey);
            dispatch({
                type: ActionTypes.setProductData,
                productData: initial && jsonData ? JSON.parse(jsonData) : cloneDeep(productData),
                fetchedProductData: initial ? productData : fetchedProductData
            });
        }

        if (initial) {
            const autoSave = autoSaveEnabled();
            dispatch(setAutoSave(autoSave));
        }
    };

export function removeProductData(): RemoveProductDataAction {
    return {
        type: ActionTypes.removeProductData
    };
}

export const saveInProgress = (saveInProgress: boolean): SaveInProgressAction => ({
    type: ActionTypes.saveInProgress,
    saveInProgress
});

const changeDetected = (actualProduct: Product, fetchedProduct: Product): boolean => {
    return !!diff(_.omit(actualProduct, FIELDS_TO_OMIT_WHEN_COMPARE_PRODUCT_DATA), _.omit(fetchedProduct, FIELDS_TO_OMIT_WHEN_COMPARE_PRODUCT_DATA));
};

export const setAutoSave = (enabled: boolean): ThunkAction<void, AppState, {}, SetAutoSaveAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        if (enabled) {
            try {
                localStorage.setItem(LocalStorageKey.AUTO_SAVE_DISABLED_FOR_PRODUCTS, "true");
            } catch (e) {
                parseError(new Error("Nie udało się zachować informacji o autozapisie."))
            }
        } else {
            localStorage.removeItem(LocalStorageKey.AUTO_SAVE_DISABLED_FOR_PRODUCTS);
        }

        const storageKey = LocalStorageKey.PRODUCT(getState().ProductData.productData);
        if (enabled) {
            localStorage.removeItem(storageKey);
            dispatch({
                type: ActionTypes.setAutoSave,
                autoSaveEnabled: enabled
            });
            await dispatch(autoSaveReport());
        } else {
            const productData = getState().ProductData.productData;
            dispatch({
                type: ActionTypes.setAutoSave,
                autoSaveEnabled: enabled
            });

            dispatch({
                type: ActionTypes.changesDetected,
                changesDetected: changeDetected(productData as Product, getState().ProductData.fetchedProductData as Product)
            })
        }
    };

export const clearEmpties = <T extends Record<string, any>, >(o?: T): T => {
    if (!o) {
        return o as any;
    }
    Object.getOwnPropertyNames(o).forEach(k => {
        if (!o[k] || typeof o[k] !== "object") {
            return;
        }

        if (Array.isArray(o[k])) {
            o[k].forEach(clearEmpties);
            return;
        }
        clearEmpties(o[k]);
        if (Object.keys(o[k]).length === 0) {
            delete o[k];
        }
    });

    return o
};


export const autoSaveReport = (): ThunkAction<void, AppState, {}, SetProductDataAction | SaveInProgressAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const productData = getState().ProductData.productData;
        if (productData) {
            SaveManager.attemptAutoSave().then(async () => {
                dispatch(saveInProgress(true));
                try {
                    const id = productData._id;
                    const result = await axios.put<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${productData._id}`, productData, getAxiosConfig());
                    await dispatch(updateProductInList(id));
                    const fetchedProductData: any = _.omit(result.data, ["createdAt", "updatedAt", "__v"]);
                    dispatch(setProductData(clearEmpties(getState().ProductData.productData), fetchedProductData, false));
                    const storageKey = LocalStorageKey.PRODUCT(getState().ProductData.productData);
                    localStorage.removeItem(storageKey);
                    dispatch({
                        type: ActionTypes.changesDetected,
                        changesDetected: false
                    });
                } catch (e) {
                    parseError(e);
                } finally {
                    dispatch(saveInProgress(false));
                    SaveManager.done();
                }
            });
        }
    };

const getTemplateIdFromProduct = (product?: Product): string | undefined => {
    if (product === undefined) {
        return undefined;
    }
    if (!product.primaryCategory.template) {
        return undefined;
    }
    return (product.primaryCategory.template as ProductTemplate)._id || undefined;
};

const saveGuidelines = (guidelines: TemplateGuideLine[]): ThunkAction<void, AppState, {}, SaveInProgressAction> =>
    async (dispatch, getState) => {
        const templateId = getTemplateIdFromProduct(getState().ProductData.productData);
        if (templateId) {
            GuidelinesSaveManager.attemptAutoSave().then(async () => {
                dispatch(saveInProgress(true));
                try {
                    await axios.patch(process.env.REACT_APP_DATA_ENDPOINT + `/templates/${templateId}/guidelines`, guidelines, getAxiosConfig());
                    dispatch(saveInProgress(false));
                } catch (e) {
                    parseError(e);
                } finally {
                    GuidelinesSaveManager.done();
                }
            });
        }
    };

export const GUIDELINES_PATH = "primaryCategory.template.guidelines";


export const getValuesForGenerator = (pathForGenerator: string[]): ThunkAction<any[], AppState, {}, UpdateValueAction | SetChangesDetectedAction> =>
    (dispatch, getState) => {
        const state = getState();
        return pathForGenerator.map(path => _.get(state.ProductData.productData, path));
    };

const filterElementsByGroup = (variants?: ProductElementVariant[], element?: ProductElement): ProductElementVariant[] | undefined => {
    return variants?.filter(v => v.element._id === element?._id);
}

export const updateValue = (path: string, value: any): ThunkAction<void, AppState, {}, UpdateValueAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const productData: Product = getState().ProductData.productData as Product;
        _.set(productData, path, value);

        if (path === "isActive" && !value) {
            _.set(productData, "shop.forSale", false);
        }

        if (path.includes("shop.elements") || path.includes("shop.elementsVariants")) {
            const variant1Elements = filterElementsByGroup(productData?.shop?.elementsVariants, productData?.shop?.elements?.[0]) || [];
            const variant2Elements = filterElementsByGroup(productData?.shop?.elementsVariants, productData?.shop?.elements?.[1]) || [];
            const combinations = productData?.shop?.combinations || [];
            let calculatedCombinations: ProductElementVariantsCombination[] = variant1Elements.flatMap(v1e => variant2Elements.map(v2e => ({
                variantsIds: [v1e._id as string, v2e._id as string],
                price: 0
            })));

            if (!calculatedCombinations.length) {
                calculatedCombinations = (variant1Elements.length ? variant1Elements : variant2Elements).map(el => ({
                    price: 0,
                    variantsIds: [el._id as string]
                }))
            }

            calculatedCombinations = calculatedCombinations.map(combination => ({
                variantsIds: combination.variantsIds,
                price: combinations.find(c => c.variantsIds.length === combination.variantsIds.length && c.variantsIds.every(el => combination.variantsIds.some(ve => ve === el)))?.price || 0
            }));

            _.set(productData, "shop.combinations", calculatedCombinations);
        }

        dispatch({
            type: ActionTypes.updateValue,
            productData
        });
        if (getState().ProductData.autoSaveEnabled) {
            if (path === GUIDELINES_PATH) {
                await dispatch(saveGuidelines(value))
            } else {
                await dispatch(autoSaveReport());
            }
        } else {
            const storageKey = LocalStorageKey.PRODUCT(getState().ProductData.productData);
            const productData = getState().ProductData.productData;
            const changesDetected = changeDetected(productData as Product, getState().ProductData.fetchedProductData as Product);
            if (changesDetected) {
                try {
                    localStorage.setItem(storageKey, JSON.stringify(productData));
                } catch (e) {
                    parseError(new Error("Nie udało się zachować tymczasowej wersji produktu."));
                }
            } else {
                localStorage.removeItem(storageKey);
            }
            dispatch({
                type: ActionTypes.changesDetected,
                changesDetected
            })
        }
    };

export const revertProductChanges = (): ThunkAction<void, AppState, {}, SetProductDataAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const storageKey = LocalStorageKey.PRODUCT(getState().ProductData.productData);
        localStorage.removeItem(storageKey);
        dispatch({
            type: ActionTypes.setProductData,
            productData: getState().ProductData.fetchedProductData as Product
        });
        dispatch({
            type: ActionTypes.changesDetected,
            changesDetected: false
        })
    };

export type AllActions =
    SetProductDataAction
    | RemoveProductDataAction
    | UpdateValueAction
    | SetAutoSaveAction
    | SaveInProgressAction
    | SetChangesDetectedAction;
