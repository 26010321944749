import {ActionTypes, AllActions} from "../ActionCreators/ProductData";
import {initialState, ProductDataState} from "../State/ProductData";
import {cloneDeep} from "lodash";

export default function ProductDataReducer(state: ProductDataState = initialState, action: AllActions): ProductDataState {
    switch (action.type) {
        case ActionTypes.setProductData: {
            return {
                ...state,
                productData: action.productData,
                fetchedProductData: action.fetchedProductData ? action.fetchedProductData : cloneDeep(action.productData)
            };
        }
        case ActionTypes.setAutoSave: {
            return {
                ...state,
                autoSaveEnabled: action.autoSaveEnabled
            };
        }
        case ActionTypes.saveInProgress: {
            return {
                ...state,
                saveInProgress: action.saveInProgress
            };
        }
        case ActionTypes.removeProductData: {
            return {
                ...state,
                productData: undefined,
                fetchedProductData: undefined
            }
        }
        case ActionTypes.updateValue: {
            return {
                ...state,
                productData: action.productData
            }
        }
        case ActionTypes.changesDetected: {
            return {
                ...state,
                changesDetected: action.changesDetected
            }
        }
        default:
            return state;
    }
}

