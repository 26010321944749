import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import CategoryAutocomplete from "../../../../Components/CategoryAutocomplete/CategoryAutocomplete";
import styles from './categories.module.scss';

class Categories extends Component {
    public render = (): JSX.Element =>
        <Box title={"Kategorie"}>
            <CategoryAutocomplete
                className={styles.categories}
                label={"Kategoria Główna"}
                path={"primaryCategory"}
            />
            <CategoryAutocomplete
                className={styles.categories}
                label={"Kategorie Dodatkowe"}
                path={"categories"}
                multiple={true}
            />
        </Box>
}

export default Categories;
