import React, {PropsWithChildren, useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import './variantCard.scss'
import DeleteIcon from "@material-ui/icons/Delete";
import {red} from "@material-ui/core/colors";
import {AppState} from "../../../../../../../Store";
import _ from "lodash";
import {connect} from "react-redux";
import {ProductAttribute, ProductAttributeType} from "mesmetric-v2-common/models";
import {FileCopy} from "@material-ui/icons";

interface ExternalProps {
    path: string
    title?: string
    onVariantRemove: () => void,
    newlyAdded: boolean
    variantId?: string
    attributesProvided: ProductAttribute.Any[],
    onVariantClone: () => void
}

interface StateProps {
    variantAttributes: any
}

type Props = PropsWithChildren<StateProps & ExternalProps>

const VariantCard: React.FC<Props> = (props) => {
    const [state, setState] = useState({
        contentExpanded: props.newlyAdded,
        newlyAdded: props.newlyAdded
    });

    useEffect(() => {
        setState({
            contentExpanded: state.contentExpanded || props.newlyAdded,
            newlyAdded: props.newlyAdded
        })
    }, [props, state.contentExpanded]);

    const handleExpandClick = () => {
        setState({
            contentExpanded: !state.contentExpanded,
            newlyAdded: false
        });
    };

    const getDescription = (): JSX.Element => {
        return (<> {props.attributesProvided.map((attributeProvided, index) => {
                const id = Object.keys(props.variantAttributes).find(id => id === attributeProvided._id);
                if (attributeProvided.type === ProductAttributeType.Dictionary) {
                    const values = id ? attributeProvided.dictionary.items
                        .filter(item => props.variantAttributes[id]
                            .includes(item._id)).map(item => item.label.pl) : [];
                    return (values?.length > 0 ?
                        <p className={"description"}
                           key={index}>{attributeProvided.label.pl}: <span>{values.toString()}</span>
                        </p> : undefined);
                } else {
                    const value = id ? props.variantAttributes[id] : undefined;
                    return (value ?
                        <p className={"description"} key={index}>{attributeProvided.label.pl} <span>{value} </span>
                        </p> : undefined);
                }
            })
            }</>
        );
    };

    const createVariantDescription = (): JSX.Element => {
        return <>
            {!props.variantAttributes || Object.keys(props.variantAttributes).filter(key => Object.keys(props.variantAttributes[key]).length !== 0).length < 1 ?
                <span>Brak przypisanych wartości do atrybutów</span> : getDescription()
            }
        </>
    };


    const prettyPrint = (value: string): string => `${value.toLowerCase().charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
    return (
        <Card className={"variant-card"}>
            <CardHeader
                className={"card-header"}
                action={
                    <>
                        <IconButton
                            className={"remove"}
                            onClick={() => props.onVariantRemove()}
                            aria-label="remove">
                            <DeleteIcon style={{color: red[500], fontSize: 25}}/>
                        </IconButton>
                        <IconButton
                            className={"clone"}
                            onClick={() => props.onVariantClone()}
                            aria-label="copy">
                            <FileCopy style={{fontSize: 25}}/>
                        </IconButton>
                        <IconButton
                            className={state.contentExpanded ? "expand" : "expandOpen"}
                            onClick={handleExpandClick}
                            aria-expanded={state.contentExpanded}
                            aria-label="show more">
                            <ExpandMoreIcon style={{fontSize: 25}}/>
                        </IconButton>
                    </>
                }
                title={props.title ? prettyPrint(props.title) : "Brak nazwy"}/>
            <Collapse in={!state.contentExpanded} timeout="auto" unmountOnExit>
                <CardContent className={"card-description"}>
                    {createVariantDescription()}
                    <div className={"overlay"}>
                        <IconButton
                            className={"icon"}
                            onClick={handleExpandClick}
                            aria-expanded={state.contentExpanded}
                            aria-label="show more">
                            <ExpandMoreIcon style={{fontSize: 30}}
                                            className={state.contentExpanded ? "expand" : "expandOpen"}/>
                        </IconButton>
                    </div>
                </CardContent>
            </Collapse>
            <Collapse in={state.contentExpanded} timeout="auto" unmountOnExit>
                <CardContent className={"card-content"}>
                    {props.children}
                </CardContent>
            </Collapse>
        </Card>
    );
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        variantAttributes: _.get(state.ProductData.productData, `${externalProps.path}`),
    });
};

export default connect(mapStateToProps)(VariantCard);
