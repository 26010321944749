import React, {Component} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Category} from "mesmetric-v2-common/models";
import {CircularProgress} from "@material-ui/core";
import {AppState} from "../../Store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../ActionCreators/ProductData";
import {connect} from "react-redux";
import _, {cloneDeep} from "lodash";
import Dictionary from "../../DataProviders/Dictionary";

interface ExternalProps {
    path: string,
    label: string | JSX.Element,
    optionsProvider?: () => Promise<any>,
    optionsMapper?: (item: Category, index?: number, array?: Category[]) => MappedOption,
    disableRemove?: boolean,
    multiple?: boolean,
    disabledByPath?: string,
    renderOption?: (item: any) => JSX.Element,
    invalid?: boolean,
    className?: string,
    fullWidth?: boolean,
    onValueChange?: (id: Category) => void,
}

interface DispatchProps {
    onChange: (value: any) => void,
}

type OptionType = {
    [key: string]: any,
}

interface CategoryAutocompleteState {
    loaded: boolean,
    options: OptionType[],
}

interface StateProps {
    value: any,
    label: string | JSX.Element,
    optionsProvider?: () => Promise<Category[]>,
    optionsMapper?: (item: Category, index?: number, array?: Category[]) => MappedOption,
    disableRemove?: boolean,
    multiple?: boolean,
    disabled?: boolean,
    renderOption?: (item: any) => JSX.Element,
    invalid?: boolean,
    className?: string,
    fullWidth?: boolean,
    onValueChange?: (id: Category) => void,
}

export interface MappedOption {
    labelDropdown: string,
    labelPill: string | undefined,
    selectable: boolean,
    parentCategory: string | undefined,
    value: Category,
}

export type Props = StateProps & DispatchProps;

class CategoryAutocomplete extends Component<Props, CategoryAutocompleteState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loaded: false,
            options: [],
        };
    }

    async componentDidMount() {
        await this.updateOptions();
    }

    private ancestorNames = (options: Category[], id: string): string[] => {
        if (!id) return []
        const parent = options.find(v => v._id === id)
        if (!parent) return []
        return [...[parent?.name?.pl], ...this.ancestorNames(options, parent.parent as string)];
    }

    private defaultMapper = (item: Category): MappedOption => ({
        labelDropdown: item.name?.pl,
        labelPill: item.path?.pl?.replace(/-/g, " "),
        selectable: item?.children?.length === 0,
        parentCategory: item?.parent as string || "inne",
        value: item
    });

    private updateOptions = async () => {
        this.setState({
            loaded: false
        });
        const result = cloneDeep((this.props.optionsProvider && await this.props.optionsProvider()) || await Dictionary.getCategories());
        // complete options with parent category
        result.forEach(option => {
            option.parent = this.ancestorNames(result, option.parent as string).reverse().join(" / ")
        });
        const options = result.map(this.props.optionsMapper || this.defaultMapper);

        this.setState({
            options,
            loaded: true
        });
    }

    public render = () => {
        if (!this.state?.loaded)  return <div><CircularProgress color="inherit" size={20}/></div>;
        let props: any = {
            fullWidth: this.props.fullWidth || false,
        };
        if (this.props.multiple) {
            props = {
                multiple: true,
                value: this.state.options.filter(option => (this.props.value || []).find((selected: any) => selected._id === option.value?._id)) || null,
                onChange: (event: any, newValue: any) => {
                    this.props.onValueChange && this.props.onValueChange(newValue?.value);
                    this.props.onChange(newValue?.map((newItems: any) => newItems.value));
                },
                ...props,
            }
        } else {
            props = {
                value: this.state.options.find(option => option.value?._id === this.props.value?._id) || null,
                onChange: (event: any, newValue: any) => {
                    this.props.onValueChange && this.props.onValueChange(newValue?.value);
                    this.props.onChange(newValue?.value)
                },
                ...props,
            }
        }

        return <Autocomplete
            className={this.props.className}
            options={this.state?.options?.sort((a, b) => ('' + a.parentCategory).localeCompare(b.parentCategory)) || []}
            getOptionLabel={(option: MappedOption) => option.labelPill || ""}
            noOptionsText={"Brak opcji"}
            groupBy={(option: MappedOption) => option.parentCategory}
            loading={!this.state.loaded}
            renderOption={(option: MappedOption) => option.labelDropdown || ""}
            disabled={!this.state.loaded}
            renderInput={(params) =>
                <TextField {...params}
                           label={this.props.label}
                           variant="outlined"
                           size={"small"}
                           InputProps={{
                               ...params.InputProps,
                               endAdornment: (
                                   <React.Fragment>
                                       {!this.state.loaded ? <CircularProgress color="inherit" size={20}/> : null}
                                       {params.InputProps.endAdornment}
                                   </React.Fragment>
                               ),
                           }}
                />
            }
            {...props}
        />
    }
}

export const CategoryAutocompleteNotConnected = CategoryAutocomplete;

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path),
    label: externalProps.label,
    optionsMapper: externalProps.optionsMapper,
    optionsProvider: externalProps.optionsProvider,
    disableRemove: externalProps.disableRemove,
    multiple: externalProps.multiple,
    renderOption: externalProps.renderOption,
    invalid: externalProps.invalid,
    className: externalProps.className,
    fullWidth: externalProps.fullWidth,
    onValueChange: externalProps.onValueChange,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => {
        return dispatch(updateValue(externalProps.path, value))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAutocomplete)
