import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import {green} from "@material-ui/core/colors";
import {AddRounded} from "@material-ui/icons";
import ItemForm, {ItemData} from "../../Categories/ItemForm/ItemForm";
import {BackendCategory} from "../../../ActionCreators/Categories";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, LinearProgress} from "@material-ui/core";
import styles from "../../Categories/AddItem/AddItem.module.scss";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";

export interface Props {
    doAction: (userData: ItemData) => Promise<string | void>,
    otherItems?: BackendCategory[],
    className?: string,
    parentId?: string,
}

interface State {
    showModal: boolean,
    userData?: ItemData,
    inProgress: boolean,
    errorMessage?: string
}

class AddItem<T extends Props = Props> extends Component<T, State> {
    constructor(props: T, userData?: ItemData) {
        super(props);
        this.state = {
            userData: {...userData, ...{parentId: this.props.parentId}},
            showModal: false,
            inProgress: false,
        }
    }

    protected getButton = (func: () => void): JSX.Element =>
        <IconButton
            color={"primary"}
            onClick={func}
            className={this.props.className}
        >
            <AddRounded style={{color: green[500], fontSize: 25}}/>
        </IconButton>;

    protected buttonDisabled = (): boolean =>
        !this.state.userData?.name || !this.state.userData.code || !!this.props.otherItems?.some(item => item.name.pl === this.state.userData?.name || item.code === this.state.userData?.code);

    protected getSaveButtonLabel = (): string => "Dodaj";

    public render = (): JSX.Element =>
        <>
            {this.getButton(() => this.setState({showModal: true}))}
            {this.state.showModal &&
                <Dialog
                    open={true}
                >
                    <DialogContent className={styles.body}>
                        <ItemForm
                            disabled={this.state.inProgress}
                            userData={this.state.userData}
                            onChange={userData => this.setState({userData})}
                        />
                        {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}
                    </DialogContent>
                    <DialogActions className={styles.actions}>
                        {this.state.inProgress && <LinearProgress className={styles.add}/>}
                        {!this.state.inProgress && <Button
                            className={styles.add}
                            color={"primary"}
                            variant={"contained"}
                            disabled={this.buttonDisabled()}
                            onClick={async () => {
                                if (!this.state.userData) {
                                    return;
                                }
                                this.setState({
                                    errorMessage: undefined,
                                    inProgress: true
                                });
                                const errorMessage = await this.props.doAction(this.state.userData);
                                if (errorMessage) {
                                    this.setState({
                                        errorMessage,
                                        inProgress: false
                                    })
                                } else {
                                    this.setState({
                                        showModal: false,
                                        userData: undefined,
                                        inProgress: false
                                    })
                                }
                            }}
                        >
                            {this.getSaveButtonLabel()}
                        </Button>}
                        <Button
                            disabled={this.state.inProgress}
                            color="primary"
                            onClick={() => this.setState({showModal: false, userData: undefined, errorMessage: undefined})}>
                            Odrzuć
                        </Button>
                    </DialogActions>
                </Dialog>}
        </>
}

export default AddItem;
