import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import Typography from "@material-ui/core/Typography";
import PaddedPaper from "../../Components/PaddedPaper/PaddedPaper";
import {AppState} from "../../Store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {connect} from "react-redux";
import styles from "./Dashboard.module.scss";

type DispatchProps = {
};

type StateProps = {
};

type DashboardProps = RouteComponentProps<{ id: string, tab: string }> & DispatchProps & StateProps;

const Dashboard: React.FC<DashboardProps> = () => {
    return <div className={styles.content}>
        <PaddedPaper>
            <Typography paragraph>
                Wybierz sekcję z menu po lewej stronie
            </Typography>
        </PaddedPaper>
    </div>;
}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
