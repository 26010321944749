import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {TextField as MaterialTextField} from "@material-ui/core";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue as categoryUpdateValue} from "../../../../ActionCreators/CategoryData";
import {updateValue as productUpdateValue} from "../../../../ActionCreators/ProductData";
import {updateValue as brandUpdateValue} from "../../../../ActionCreators/BrandData";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";

interface ExternalProps {
    path: string,
    label?: string,
    type?: string,
    valueConverter?: (value?: string) => any,
    multiline?: boolean,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,
    autoFocus?: boolean,
    required?: boolean,
    variant?: "standard" | "outlined",
    dataType?: "Product" | "Brand" | "Category",
    onBlur?: () => void,
    InputProps?: Partial<StandardInputProps>,
    className?: string
}

interface StateProps {
    value: any,
    label?: string,
    type?: string,
    valueConverter?: (value?: string) => any,
    multiline?: boolean,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,
    autoFocus?: boolean,
    required?: boolean,
    variant?: "standard" | "outlined",
    onBlur?: () => void,
    InputProps?: Partial<StandardInputProps>,
    className?: string
}

interface DispatchProps {
    onChange: (value: any) => void
}

type TextFieldProps = StateProps & DispatchProps;

class TextField extends Component<TextFieldProps> {
    private onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): any => {
        const value = event.target.value ? event.target.value : undefined;
        if (this.props.valueConverter) {
            return this.props.onChange(this.props.valueConverter(value));
        }
        return this.props.onChange(value);
    };

    public render = () => {
        return <MaterialTextField
            className={this.props.className}
            label={this.props.label}
            variant={this.props.variant || "outlined"}
            size={"small"}
            value={this.props.value || ""}
            onChange={this.onChange}
            error={this.props.error}
            disabled={this.props.disabled}
            helperText={this.props.helperText}
            type={this.props.type}
            multiline={this.props.multiline}
            autoFocus={this.props.autoFocus}
            required={this.props.required}
            onBlur={this.props.onBlur}
            InputProps={this.props.InputProps}
        />;
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: (() => {
            switch (externalProps.dataType) {
                case "Brand": return _.get(state.BrandData.brandData, externalProps.path, "");
                case "Category": return _.get(state.CategoryData.categoryData, externalProps.path, "");
                default: return _.get(state.ProductData.productData, externalProps.path, "");
            }
        })(),
    ...externalProps
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch((() => {
        switch (externalProps.dataType) {
            case "Brand": return brandUpdateValue
            case "Category": return categoryUpdateValue
            default: return productUpdateValue
        }
    })()(externalProps.path, value))
});

export const TextFieldNotConnected = TextField;

export default connect(mapStateToProps, mapDispatchToProps)(TextField)
