import {Notification} from "../State/Notifications";

export enum ActionTypes {
    addNotification = "addNotification",
    removeNotification = "removeNotification"
}

export interface AddNotificationAction {
    type: ActionTypes.addNotification
    notification: Notification
}

export interface RemoveNotificationAction {
    type: ActionTypes.removeNotification,
    notification: Notification
}

export const addNotification = (notification: Notification): AddNotificationAction => ({
        type: ActionTypes.addNotification,
        notification
    }
);

export const removeNotification = (notification: Notification): RemoveNotificationAction => ({
        type: ActionTypes.removeNotification,
        notification
    }
);

export type AllActions = AddNotificationAction | RemoveNotificationAction;