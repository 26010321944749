import React, {useState} from "react";
import * as Styled from "./ProductPreview.styled";
import {Button, Dialog} from "@material-ui/core";
import {useSelector} from "react-redux";
import {AppState, store} from "../../../../Store";
import axios from "axios";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {
    ProductElementPrestaModel,
    ProductPrestaModel,
    ProductVariantPrestaModel
} from "mesmetric-v2-common/models/presta/Product";
import {addNotification} from "../../../../ActionCreators/Notifications";

const SelectedVariant: React.FC<{
    element: ProductElementPrestaModel,
    elementIndex: number,
    selectedVariants: ProductVariantPrestaModel[],
    onSelected: (selected: ProductVariantPrestaModel) => void
}> = (
    {
        element,
        elementIndex,
        selectedVariants,
        onSelected
    }) => {
    const [selectVisible, setSelectVisible] = useState(false);
    const selectedVariant = selectedVariants[elementIndex];
    if (!selectedVariant) {
        throw new Error("BOOM");
    }
    return <Styled.Element onClick={() => setSelectVisible(true)}>
        <div className={"title"}>{element.name.pl}</div>
        <div className={"content"}>
            <div className={"image"}>
                <img src={selectedVariant.imageSrc} alt={""}/>
            </div>
            <div className={"variant-description"}>
                <div>Wybrano</div>
                {selectedVariant.name.pl}
            </div>
        </div>
        {selectVisible &&
        <div className={"select"}>
            {element.variants.map((el, index) =>
                <div key={index} className={"content"} onClick={(ev) => {
                    ev.stopPropagation();
                    setSelectVisible(false);
                    onSelected(el);
                }}>
                    <div className={"image"}>
                        <img src={el.imageSrc} alt={""}/>
                    </div>
                    <div className={"variant-description"}>
                        {el.name.pl}
                    </div>
                </div>)}
        </div>}
    </Styled.Element>
}

const ProductPreview: React.FC<{ show?: boolean }> = ({show}) => {
    const [fetchedProduct, setFetchedProduct] = useState<ProductPrestaModel | undefined>();
    const productId = useSelector<AppState, string>(state => state.ProductData.productData?._id || "");
    const [showPreview, setShowPreview] = useState(true);
    const [selectedVariants, setSelectedVariants] = useState<ProductVariantPrestaModel[]>([]);

    return <>
        <Styled.Button
            size={"small"}
            variant={"contained"}
            onClick={async () => {
                const response = (await axios.get(process.env.REACT_APP_DATA_ENDPOINT + `/products/render/${show ? "show" : "shop"}/` + productId, getAxiosConfig())).data;
                if (typeof response === "string") {
                    store.dispatch(addNotification({type: "error", message: response}));
                }
                else {
                    const product = response as ProductPrestaModel;
                    setSelectedVariants(product.elements?.filter(el => !!el.variants.length).map(el => el.variants[0]) || []);
                    setFetchedProduct(product);
                    setShowPreview(true);
                }
            }}
        >
            <span>Podgląd produktu</span>
        </Styled.Button>
        {showPreview && fetchedProduct &&
        <Dialog
            open
            fullScreen
            onClose={() => setShowPreview(false)}
        >
            <Styled.Content>
                <Styled.LeftColumn>
                    {fetchedProduct.photos.map((photo, index) =>
                        <img key={index} src={photo} alt={""}/>)}
                </Styled.LeftColumn>
                <Styled.RightColumn>
                    <div
                        className={"price"}>Cena: {(() => {
                        let price = fetchedProduct.price;
                        if (selectedVariants.length) {
                            price = fetchedProduct.combinations?.find(c => selectedVariants.every(el => c.variantsIds.includes(el?.id as string)))?.price || 0
                        }
                        return price
                    })()} EUR
                    </div>
                    {fetchedProduct.elements?.map((el, index) =>
                        <SelectedVariant
                            key={el.id}
                            selectedVariants={selectedVariants}
                            element={el}
                            elementIndex={index}
                            onSelected={selected => {
                                setSelectedVariants(selectedVariants.map((s, i) => i === index ? selected : s));
                            }}
                        />
                    )}

                    <div className={"desc"}>
                        <div className={"title"}>{"Opis produktu".toUpperCase()}</div>
                        {fetchedProduct.attributes.map((attribute, index) =>
                            <div key={index} className={"attribute"}>
                                <div className={"title"}>{attribute.name.pl}</div>
                                {attribute.elements.map(el => <div className={"attribute-element"}>
                                    {!!el.name.pl && `${el.name.pl} - `}{el.values.map((value, index) =>
                                    <span key={index}>{value.pl + ((el.values.length === index + 1) ? "" : ",")}</span>)}
                                </div>)}
                            </div>)}
                        <div className={"content"}>{fetchedProduct.description.pl}</div>
                    </div>
                </Styled.RightColumn>
            </Styled.Content>
            <Styled.Actions>
                <Button color="primary" onClick={() => setShowPreview(false)}>
                    Zamknij
                </Button>
            </Styled.Actions>
        </Dialog>}
    </>;
}

export default ProductPreview;
