import React, {useEffect, useRef, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import styles from "./ButtonPopper.module.scss";
import {LinearProgress, PropTypes} from "@material-ui/core";
import {cls} from "../../../Utils/StyleUtils";

interface Props {
    onConfirm: (id?: string) => Promise<string | void> | void,
    isClickable?: () => Promise<boolean>,
    icon?: JSX.Element,
    confirmText?: string,
    color?: PropTypes.Color,
    disabled?: boolean
}

const ButtonPopper: React.FC<Props> = (props) => {
    const [opened, setOpened] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isClickable, setIsClickable] = useState(false);
    const removeButton = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        (async () => {
            let isClickable;
            if (props.isClickable) {
                isClickable = await props.isClickable();
            } else {
                isClickable = true;
            }
            setIsClickable(isClickable);
        })()
    }, [props]);

    const getLinearColor = (): 'primary' | 'secondary' => {
        switch (props.color) {
            case "primary":
                return "primary";
            default:
                return "secondary"

        }
    }

    switch (isClickable) {
        case true: {
            return <>
                <Button
                    ref={removeButton}
                    size="small"
                    variant="contained"
                    onClick={() => setOpened(!opened)}
                    disabled={props.disabled}
                    color={props.color || "secondary"}>
                    {props.icon || <DeleteIcon/>}
                </Button>
                <Popper
                    open={opened}
                    anchorEl={removeButton.current}
                    placement={"bottom"}
                    transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className={cls(styles.popper, styles[props.color || ""])}>
                                <span>{props.confirmText || "Czy na pewno chcesz usunąć?"}</span>
                                <div className={styles.buttons}>
                                    {inProgress &&
                                    <LinearProgress
                                        color={getLinearColor()}
                                        className={styles.remove}/>}
                                    {!inProgress && <Button
                                        variant="contained"
                                        color={props.color || "secondary"}
                                        className={styles.remove}
                                        onClick={async () => {
                                            setInProgress(true);
                                            await props.onConfirm();
                                            setInProgress(false);
                                            setOpened(false)
                                        }}
                                    >
                                        <span>Tak</span>
                                    </Button>}
                                    <Button
                                        disabled={inProgress}
                                        color="primary"
                                        onClick={() => setOpened(false)}>
                                        <span>Anuluj</span>
                                    </Button>
                                </div>
                            </div>
                        </Fade>
                    )}
                </Popper>
            </>
        }
        default: {
            return null;
        }
    }
}

export default ButtonPopper;
