import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Categories.module.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {BackendCategory, fetchCategories, addCategory, searchCategories} from "../../ActionCreators/Categories";
import {ConnectedCategoryTree, selectedCategoryNode} from "../../Components/CategoryTree";
import commonStyles from "../CommonComponents/CommonStyles.module.scss";
import Box from "../ProductView/Common/Box/Box";
import {InputAdornment, TextField, IconButton} from "@material-ui/core";
import {Search, EditRounded} from "@material-ui/icons";
import {blue} from "@material-ui/core/colors";
import {ItemData} from "./ItemForm/ItemForm";
import AddItem from "./AddItem/AddItem";

interface StateProps extends RouteComponentProps {
    items: BackendCategory[],
    isSearching: boolean
}

interface DispatchProps {
    addItem: (userData: ItemData) => Promise<string | void>,
    searchCategories: () => void,
    fetchCategories: () => void
}

type Props = StateProps & DispatchProps;

class Categories extends Component<Props> {
    componentDidMount() {
        if (!this.props.items?.length) {
            this.props.fetchCategories();
        }
    }

    public render = (): JSX.Element =>
        <div className={commonStyles.content}>
            <Box title={"Kategorie"}
                 className={styles.box}
                 headerComponent={<AddItem className={styles.add}
                                           otherItems={this.props.items}
                                           doAction={this.props.addItem}/>}>
                <TextField
                    className={styles.search}
                    label={"Szukaj..."}
                    value={"Jeszcze nie działa :("}
                    InputProps={{
                        type: 'search',
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        console.log(event.target.value);
                    }}/>
                <ConnectedCategoryTree
                    selectedNodeCallback={categoryNode => ({
                        ...categoryNode,
                        isSelected: categoryNode.category.isActive,
                    })}
                    onLabelHover={(categoryNode: selectedCategoryNode): JSX.Element => {
                        return <><AddItem
                            className={styles.add}
                            otherItems={this.props.items}
                            doAction={this.props.addItem}
                            parentId={categoryNode.category._id}
                        /><IconButton
                            color={"secondary"}
                            onClick={() => {this.props.history.push(`categories/edit/${categoryNode.category._id}`)}}
                            className={styles.edit}
                        >
                            <EditRounded style={{color: blue[500], fontSize: 25}}/>
                        </IconButton>
                        </>
                    }}
                />
            </Box>
        </div>;
}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
    ...externalProps,
    items: state.Categories.categories,
    isSearching: state.Categories.isSearching,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addItem: (userData) => dispatch(addCategory(userData)),
    searchCategories: () => dispatch(searchCategories()),
    fetchCategories: () => dispatch(fetchCategories()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Categories));
