import axios from 'axios'
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";
import {fetchBrands} from "../ActionCreators/Brands";
import {fetchDesigners} from "../ActionCreators/Designers";
import {fetchCategories, fetchCategoriesTree} from "../ActionCreators/Categories";
import {fetchProductsElements} from "../ActionCreators/ProductElements";

const {store} = require("../Store");

const cachedData = {}

const apiEndpoint = process.env.REACT_APP_DATA_ENDPOINT;

const getCollectionData = async (collectionPath) => {

    if (cachedData[collectionPath]) {
        return cachedData[collectionPath];
    }
    return cachedData[collectionPath] = axios.get(`${apiEndpoint}/${collectionPath}`, getAxiosConfig())
        .then(result => {
            return cachedData[collectionPath] = result.data;
        }).catch(parseError)
};

export default {
    getBrands: async () => {
        if (!store.getState().Brands.brands?.length) {
            await store.dispatch(fetchBrands());
        }
        return store.getState().Brands.brands;
    },
    getDesigners: async () => {
        if (!store.getState().Designers.designers?.length) {
            await store.dispatch(fetchDesigners());
        }
        return store.getState().Designers.designers;
    },
    getPriceTypes: async () => {
        return getCollectionData('priceTypes');
    },
    getCurrencies: async () => {
        return getCollectionData('currencies');
    },
    getCategories: async () => {
        if (!store.getState().Categories.categories?.length) {
            await store.dispatch(fetchCategories());
        }
        return store.getState().Categories.categories;
    },
    getCategoriesTree: async () => {
        if (!store.getState().Categories.tree?.length) {
            await store.dispatch(fetchCategoriesTree());
        }
        return store.getState().Categories.tree;
    },
    getProductStyles: async () => {
        return getCollectionData('productStyles');
    },
    getProductValues: async () => {
        return getCollectionData('productValues');
    },
    getProductsElements: async () => {
        if (!store.getState().ProductElements.elements?.length) {
            await store.dispatch(fetchProductsElements());
        }
        return store.getState().ProductElements.elements;
    }
}
