import {Category} from "mesmetric-v2-common/models";
import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {setFilter} from "./ProductsFilters";
import {ItemData} from "../Views/Categories/ItemForm/ItemForm";

export interface BackendCategory extends Category {
    isRemovable?: boolean
    _id: string
}

export enum ActionTypes {
    setCategories = "setCategories",
    setCategoriesTree = "setCategoriesTree",
    addCategories = "addCategories",
    removeCategories = "removeCategories"
}

export interface SetCategoriesTreeAction {
    type: ActionTypes.setCategoriesTree
    tree: BackendCategory[]
}

export interface SetCategoriesAction {
    type: ActionTypes.setCategories
    categories: BackendCategory[]
}

export interface AddCategoriesAction {
    type: ActionTypes.addCategories
    categories: string[]
}

export interface RemoveCategoriesAction {
    type: ActionTypes.removeCategories
    categories: string[]
}

function setCategoriesTree(tree: BackendCategory[]): SetCategoriesTreeAction {
    return {
        type: ActionTypes.setCategoriesTree,
        tree
    };
}

function setCategories(categories: BackendCategory[]): SetCategoriesAction {
    return {
        type: ActionTypes.setCategories,
        categories
    };
}

export const MESSAGES_FROM_SERVER: { [key: string]: string } = {
    CATEGORY_NOT_FOUND: "Nie ma takiej kategorii",
    CATEGORY_HAS_PRODUCTS: "Istnieją produkty przypisane do tej kategorii",
};

export const addCategory = (userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/add`, {...userData}, getAxiosConfig());
            dispatch(fetchCategories());
        } catch (e) {
            parseError(e);
        }
    };

export const addCategories = (categoriesToAdd: string[]): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        const categories: string[] = getState().ProductsFilters.categories || [];
        dispatch(setFilter("categories", [...new Set([...categories, ...categoriesToAdd])]))
    };

export const clearCategories = (): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        dispatch(setFilter("categories", undefined))
    };

export const removeCategories = (categoriesToRemove: string[]): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        const categories: string[] = getState().ProductsFilters.categories || [];
        dispatch(setFilter("categories", categories.filter(id => !categoriesToRemove.some(newId => newId === id))));
    };

export const deleteCategory = (): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch, getState) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/delete/` + getState().CategoryData.categoryData?._id, {}, getAxiosConfig());
            await dispatch(fetchCategories());
        } catch (e) {
            parseError(e);
        }
    };

export const isCategoryRemovable = (): ThunkAction<Promise<boolean>, AppState, {}, never> =>
    async (dispatch, getState) => {
        try {
            return (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/isRemovable/` + getState().CategoryData.categoryData?._id, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
            return false
        }
    };

export function fetchCategoriesTree(): any {
    return async (dispatch: any) => {
        try {
            const categories = (await axios.get<BackendCategory[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/tree`, getAxiosConfig())).data;
            dispatch(setCategoriesTree(categories));
        } catch (e) {
            parseError(e)
        }
    }
}

export function searchCategories(): any {

}

export function fetchCategories(): any {
    return async (dispatch: any) => {
        try {
            const categories = (await axios.get<BackendCategory[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/categories`, getAxiosConfig())).data;
            dispatch(setCategories(categories));
        } catch (e) {
            parseError(e)
        }
    }
}

export type AllActions = SetCategoriesAction | SetCategoriesTreeAction | AddCategoriesAction | RemoveCategoriesAction;
