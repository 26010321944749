import React, {Component} from "react";
import "./box.scss";

interface BoxProps {
    className?: string,
    title: string,
    draggable?: boolean,
    headerComponent?: JSX.Element,
    hideHeader?: boolean
}

class Box extends Component<BoxProps> {
    public render = (): JSX.Element =>
        <div
            className={`box${this.props.className ? " " + this.props.className : ""}`}
        >
            {!this.props.hideHeader &&
            <div className={"box-header"}>
                {this.props.title}
                {this.props.headerComponent}
            </div>}
            <div className={"box-content"}>
                {this.props.children}
            </div>
        </div>
}

export default Box;