import React, {useCallback, useState} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../../Store";
import {get} from "lodash";
import styles from "./ElementsVariants.module.scss";
import Button from "@material-ui/core/Button";
import GetValue from "../../../Common/GetValue";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import {getCroppedSrc} from "../../../../../Utils/PhotoUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import PhotoCrop from "../../../ProductDetails/FilesUploaded/Photos/PhotoCrop/PhotoCrop";
import {SquareButton} from "../../Photos/Buttons";
import NewVariant from "./NewVariant/NewVariant";
import {Photo, ProductElement, ProductElementVariant} from "mesmetric-v2-common/models";
import commonStyles from "../../../../CommonComponents/CommonStyles.module.scss";

interface ExternalProps {
    path: string,
    elementPath: string,
    className?: string,
    buttonDisabledByPath: string
}

interface StateProps {
    value?: ProductElementVariant[],
    productElement?: ProductElement
}

interface DispatchProps {
    onChange: (value: any) => void
}

type ElementProps =
    {
        name: string,
        label?: string,
        image?: Photo,
        onRemove?: () => void,
        onChange?: (photo: Photo) => void,
        noTranslation?: boolean
    }
    & ({ label: string, image?: undefined } | { label?: undefined, image: Photo, onChange: (photo: Photo) => void })

const Variant: React.FC<ElementProps> = (
    {
        image,
        name,
        label,
        onRemove,
        noTranslation,
        onChange
    }) => {

    const [modalOpen, setModalOpen] = useState(false);

    if (!image || !onChange) {
        return null;
    }

    const notCropped = !image.crops?.[0]?.w || !image.crops?.[0]?.h;

    return <>
        {modalOpen &&
        <PhotoCrop
            photo={image}
            aspectName={"1:1"}
            turnOffGuidelines
            onCancel={() => setModalOpen(false)}
            onChange={photo => {
                onChange(photo);
                setModalOpen(false);
            }}
        />}
        <div className={styles.element}>
            <div className={styles.name}>
                <div className={styles.nameWithTranslation}>
                    {name}{noTranslation ?
                    <span className={commonStyles.incompleteOption}>(Brak tłumaczenia)</span> : ""}
                </div>
                {image &&
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    title={"Usuń"}
                    onClick={onRemove}>
                    <DeleteIcon/>
                </Button>}
            </div>
            {label && <div className={styles.image}>{label}</div>}
            {image &&
            <div className={styles.image}>
                <img alt={name} src={getCroppedSrc(image, {w: 100})}/>
                <div className={styles.buttons}>
                    <SquareButton
                        photo={image}
                        className={styles.button}
                        onClick={() => setModalOpen(true)}
                    />
                    {notCropped && <div className={styles.label}>Przytnij!</div>}
                </div>

            </div>}
        </div>
    </>
}

const ElementsVariants: React.FC<StateProps & ExternalProps & DispatchProps> = (
    {
        className,
        buttonDisabledByPath,
        productElement,
        ...props
    }) => {
    const [showModal, setShowModal] = useState(false);

    const variants = props.value?.filter(el => el.element._id === productElement?._id);

    const onModalClose = useCallback((variant?: ProductElementVariant) => {
        if (variant) {
            const newVariant = {
                ...variant,
                productElement
            };
            props.onChange([...props.value || [], newVariant]);
        }
        setShowModal(false);
    }, [productElement, props]);

    return <div className={styles.elements + " " + className}>
        {variants?.length ?
            <div className={styles.wrapper}>
                <Variant name={"Nazwa"} label={"Obrazek"}/>
                {variants.map((item, index) =>
                    <Variant
                        key={index}
                        name={item.name.pl}
                        noTranslation={!item.name.en}
                        onRemove={() => {
                            props.onChange(props.value?.filter(el => el._id !== item._id))
                        }}
                        onChange={photo => {
                            if (props.value) {
                                const items = [...props.value];
                                const foundItem = items.find(foundItem => foundItem._id === item._id);
                                if (foundItem) {
                                    foundItem.image = photo;
                                }
                                props.onChange(items);
                            }
                            setShowModal(false);

                        }}
                        image={item.image}/>)}
            </div> : "Brak dodanych wariantów"}
        <GetValue
            path={buttonDisabledByPath}
        >
            {value => {
                return <Button
                    className={styles.add}
                    size={"small"}
                    disabled={!value}
                    variant={"contained"}
                    onClick={() => setShowModal(true)}
                >
                    <span>Dodaj wariant</span>
                </Button>
            }}
        </GetValue>
        {showModal && productElement && <NewVariant
            productElement={productElement}
            filterOut={variants}
            onClose={onModalClose}
        />}
    </div>;
};

const mapStateToProps = (state: AppState, ownProps: ExternalProps): StateProps => ({
    value: get(state.ProductData.productData, ownProps.path),
    productElement: get(state.ProductData.productData, ownProps.elementPath)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementsVariants);
