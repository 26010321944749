import React, {useEffect, useState} from "react";
import styles from "./BrandView.module.scss";
import axios from "axios";
import {getAxiosConfig} from "../../ActionCreators/User";
import {Brand} from "mesmetric-v2-common/models";
import {RouteChildrenProps} from "react-router";
import Spinner from "../ProductView/Common/Spinner/Spinner";
import Controls from "./Controls/Controls";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../Store";
import {Action} from "redux";
import {removeBrandData, setBrandData} from "../../ActionCreators/BrandData";
import BasicData from "./BasicData/BasicData";
import Descriptions from "./Descriptions/Descriptions";
import UploadedFiles from "./UploadedFiles/UploadedFiles";
import FilesUpload from "./FilesUpload/FilesUpload";
import _ from "lodash";
import {parseError} from "../../ActionCreators/Error";

type DispatchProps = {
    setBrandData: (brand: Brand) => void,
    removeBrandData: () => void
}

type StateProps = {
    brandLoaded?: boolean
}

type BrandViewProps = RouteChildrenProps<{ id: string }> & DispatchProps & StateProps;

const BrandView: React.FC<BrandViewProps> = ({
    setBrandData,
    removeBrandData,
    match,
    ...props
}) => {
    const getBrand = async (): Promise<Brand> => {
        try {
            const response = await axios.get<Brand>(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/${match?.params.id}`, getAxiosConfig());
            if (response.data._id === undefined) {
                return Promise.reject();
            }
            return (_.omit(response.data, ["createdAt", "updatedAt", "__v"]) as Brand);
        } catch (e) {
            parseError(e);
            throw new Error();
        }
    };

    const [brandLoaded, setBrandLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            setBrandData(await getBrand());
            setBrandLoaded(true);
        })()
        return () => {
            removeBrandData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBrandData, removeBrandData]);

    return <div className={styles.content}>
        {!brandLoaded && <Spinner size={64}/>}
        {brandLoaded && <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Controls className={styles.controls}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <BasicData/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Descriptions/>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <FilesUpload/>
                    <UploadedFiles/>
                </div>
            </div>
        </>}
    </div>
}

const mapStateToProps = (state: AppState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setBrandData: (brand: Brand) => dispatch(setBrandData(brand)),
    removeBrandData: () => dispatch(removeBrandData())
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandView);
