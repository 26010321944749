import {ActionTypes, AllActions} from "../ActionCreators/CategoryData";
import {CategoryDataState, initialState} from "../State/CategoryData";
import {cloneDeep} from "lodash";

export default function CategoryDataReducer(state: CategoryDataState = initialState, action: AllActions): CategoryDataState {
    switch (action.type) {
        case ActionTypes.setCategoryData: {
            return {
                ...state,
                categoryData: action.categoryData,
                fetchedCategoryData: cloneDeep(action.categoryData)
            };
        }
        case ActionTypes.saveInProgress: {
            return {
                ...state,
                saveInProgress: action.saveInProgress
            };
        }
        case ActionTypes.removeCategoryData: {
            return {
                ...state,
                categoryData: undefined,
                fetchedCategoryData: undefined,
                changesDetected: false
            }
        }
        case ActionTypes.updateValue: {
            return {
                ...state,
                categoryData: action.categoryData
            }
        }
        case ActionTypes.changesDetected: {
            return {
                ...state,
                changesDetected: action.changesDetected
            }
        }
        default:
            return state;
    }
}

