import {BackendCategory} from "../ActionCreators/Categories";

export interface CategoriesState {
    tree?: BackendCategory[],
    categories: BackendCategory[],
    isSearching: boolean,
}

export const initialState: CategoriesState = {
    isSearching: false,
    categories: [],
};
