import {Category} from "mesmetric-v2-common/models";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import _ from "lodash";
import AutoSaveManager from "../Helpers/AutoSaveManager";
import axios from "axios";
import {diff} from "deep-diff";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {fetchCategories} from "./Categories";

export enum ActionTypes {
    setCategoryData = "setCategoryData",
    removeCategoryData = "removeCategoryData",
    updateValue = "updateValue",
    saveInProgress = "saveInProgress",
    changesDetected = "changesDetected"
}

export interface SetCategoryDataAction {
    type: ActionTypes.setCategoryData
    categoryData: Category
}

export interface RemoveCategoryDataAction {
    type: ActionTypes.removeCategoryData
}

export interface SetChangesDetectedAction {
    type: ActionTypes.changesDetected,
    changesDetected: boolean
}

export interface UpdateValueAction {
    type: ActionTypes.updateValue,
    categoryData: Category
}

export interface SaveInProgressAction {
    type: ActionTypes.saveInProgress,
    saveInProgress: boolean
}

const SaveManager = new AutoSaveManager();

export const saveCategory = (): ThunkAction<void, AppState, {}, SetChangesDetectedAction | SaveInProgressAction> =>
    async (dispatch, getState) => {
        dispatch(saveInProgress(true));
        await dispatch(sendCategory());
    };

export const sendCategory = (): ThunkAction<void, AppState, {}, SetCategoryDataAction | SaveInProgressAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const categoryData = getState().CategoryData.categoryData;
        if (!categoryData) return;
        SaveManager.attemptAutoSave().then(async () => {
            dispatch(saveInProgress(true));
            try {
                const id = categoryData._id;
                const result = await axios.put<Category>(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/edit/${id}`, categoryData, getAxiosConfig());
                await dispatch(fetchCategories());
                dispatch(setCategoryData(result.data));
                dispatch({
                    type: ActionTypes.changesDetected,
                    changesDetected: false
                });
            } catch (e) {
                parseError(e);
            } finally {
                dispatch(saveInProgress(false));
                SaveManager.done();
            }
        });
    };

export const setCategoryData = (categoryData: Category): ThunkAction<void, AppState, {}, SetCategoryDataAction> =>
    async (dispatch) => {
        dispatch({
            type: ActionTypes.setCategoryData,
            categoryData
        });
    };

export function removeCategoryData(): RemoveCategoryDataAction {
    return {
        type: ActionTypes.removeCategoryData
    };
}

export const saveInProgress = (saveInProgress: boolean): SaveInProgressAction => ({
    type: ActionTypes.saveInProgress,
    saveInProgress
});

const changeDetected = (actualCategory: Category, fetchedCategory: Category): boolean => {
    return !!diff(actualCategory, fetchedCategory);
};

const slugify = (text: string) => {
    text = text.toString().toLowerCase().trim();

    const sets = [
        {to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶἀ]'},
        {to: 'c', from: '[ÇĆĈČ]'},
        {to: 'd', from: '[ÐĎĐÞ]'},
        {to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]'},
        {to: 'g', from: '[ĜĞĢǴ]'},
        {to: 'h', from: '[ĤḦ]'},
        {to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]'},
        {to: 'j', from: '[Ĵ]'},
        {to: 'ij', from: '[Ĳ]'},
        {to: 'k', from: '[Ķ]'},
        {to: 'l', from: '[ĹĻĽŁ]'},
        {to: 'm', from: '[Ḿ]'},
        {to: 'n', from: '[ÑŃŅŇ]'},
        {to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]'},
        {to: 'oe', from: '[Œ]'},
        {to: 'p', from: '[ṕ]'},
        {to: 'r', from: '[ŔŖŘ]'},
        {to: 's', from: '[ßŚŜŞŠȘ]'},
        {to: 't', from: '[ŢŤ]'},
        {to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]'},
        {to: 'w', from: '[ẂŴẀẄ]'},
        {to: 'x', from: '[ẍ]'},
        {to: 'y', from: '[ÝŶŸỲỴỶỸ]'},
        {to: 'z', from: '[ŹŻŽ]'},
        {to: '-', from: '[·/_,:;\']'}
    ];

    sets.forEach(set => {
        text = text.replace(new RegExp(set.from,'gi'), set.to)
    });

    return text
        .replace(/[\s_-]+/g, '-')    // Replace spaces with -
        .replace(/[^-a-zа-я\u0370-\u03ff\u1f00-\u1fff]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-')        // Replace multiple - with single -
        .replace(/[^\w\s-]/g, '')    // Remove all remaining non-conformant characters
        .replace(/^-+|-+$/g, '')     // Trim
}

export const updateValue = (path: string, value: any): ThunkAction<void, AppState, {}, UpdateValueAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const categoryData = getState().CategoryData.categoryData;
        const fetchedCategoryData = getState().CategoryData.fetchedCategoryData;
        if (!categoryData || !fetchedCategoryData) {
            return;
        }
        if (path.startsWith("name")) {
            const slugPath = path.replace("name", "slug")
            const prevValue = _.get(categoryData, path, "")
            if (slugify(prevValue) === _.get(categoryData, slugPath, "")) {
                // not set to custom
                _.set(categoryData, slugPath, slugify(value));
            }
        }
        _.set(categoryData, path, value);
        dispatch({
            type: ActionTypes.updateValue,
            categoryData
        });
        const changesDetected = changeDetected(categoryData, fetchedCategoryData);
        dispatch({
            type: ActionTypes.changesDetected,
            changesDetected
        })
    };

export const revertCategoryChanges = (): ThunkAction<void, AppState, {}, SetCategoryDataAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const fetchedCategoryData = getState().CategoryData.fetchedCategoryData;
        if (!fetchedCategoryData) {
            return;
        }
        dispatch(setCategoryData(fetchedCategoryData));
        dispatch({
            type: ActionTypes.changesDetected,
            changesDetected: false
        })
    };

export type AllActions =
    SetCategoryDataAction
    | RemoveCategoryDataAction
    | UpdateValueAction
    | SaveInProgressAction
    | SetChangesDetectedAction;
