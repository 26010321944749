import React, {useEffect, useState} from "react";
import styles from "./CategoryView.module.scss";
import axios from "axios";
import {getAxiosConfig} from "../../ActionCreators/User";
import {Category} from "mesmetric-v2-common/models";
import {RouteChildrenProps, withRouter} from "react-router";
import Spinner from "../ProductView/Common/Spinner/Spinner";
import Controls from "./Controls/Controls";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../Store";
import {Action} from "redux";
import {removeCategoryData, setCategoryData} from "../../ActionCreators/CategoryData";
import Box from "../ProductView/Common/Box/Box";
import TextField from "../ProductView/Common/TextField/TextField";
import _ from "lodash";
import {parseError} from "../../ActionCreators/Error";

type DispatchProps = {
    setCategoryData: (category: Category) => void,
    removeCategoryData: () => void
}

type StateProps = {
    categoryLoaded?: boolean,
}

type CategoryViewProps = RouteChildrenProps<{ id: string }> & DispatchProps & StateProps;

const CategoryView: React.FC<CategoryViewProps> = ({
        setCategoryData,
        removeCategoryData,
        match,
    }) => {

    const getCategory = async (): Promise<Category> => {
        try {
            const response = await axios.get<Category>(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/edit/${match?.params.id}`, getAxiosConfig());
            if (response.data._id === undefined) {
                return Promise.reject();
            }
            return (_.omit(response.data, ["createdAt", "updatedAt", "__v"]) as Category);
        } catch (e) {
            parseError(e);
            throw new Error();
        }
    };

    const [categoryLoaded, setCategoryLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            setCategoryData(await getCategory());
            setCategoryLoaded(true);
        })()
        return () => {
            removeCategoryData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCategoryData, removeCategoryData])

    return <div className={styles.content}>
        {!categoryLoaded && <Spinner size={64}/>}
        {categoryLoaded && <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Controls className={styles.controls}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Box title={"Dane kategorii"}>
                                <TextField
                                    label={"Kod"}
                                    path={"code"}
                                    dataType={"Category"}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Box title={"Nazwa"}>
                                <TextField
                                    label={"Polski"}
                                    path={"name.pl"}
                                    dataType={"Category"}
                                />
                                <TextField
                                    label={"Angielski"}
                                    path={"name.en"}
                                    dataType={"Category"}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Box title={"Słowa kluczowe"}>
                                <TextField
                                    label={"Polski"}
                                    path={"keywords.pl"}
                                    dataType={"Category"}
                                    valueConverter={value => value ? value.split(",") : ""}
                                />
                                <TextField
                                    label={"Angielski"}
                                    path={"keywords.en"}
                                    dataType={"Category"}
                                    valueConverter={value => value ? value.split(",") : ""}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Box title={"Slug"}>
                                <TextField
                                    valueConverter={value => value ? value : ""}
                                    label={"Polski"}
                                    path={"slug.pl"}
                                    dataType={"Category"}
                                />
                                <TextField
                                    valueConverter={value => value ? value : ""}
                                    label={"Angielski"}
                                    path={"slug.en"}
                                    dataType={"Category"}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Box title={"Opis"}>
                                <TextField
                                    multiline
                                    valueConverter={value => value ? value : ""}
                                    label={"Polski"}
                                    path={"description.pl"}
                                    dataType={"Category"}
                                />
                                <TextField
                                    multiline
                                    valueConverter={value => value ? value : ""}
                                    label={"Angielski"}
                                    path={"description.en"}
                                    dataType={"Category"}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </div>
}

const mapStateToProps = (state: AppState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setCategoryData: (category: Category) => dispatch(setCategoryData(category)),
    removeCategoryData: () => dispatch(removeCategoryData())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryView));
