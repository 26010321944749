import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Button, PropTypes} from "@material-ui/core";
import {AppState} from "../../../../Store";
import {updateValue as productUpdateValue} from "../../../../ActionCreators/ProductData";
import {updateValue as brandUpdateValue} from "../../../../ActionCreators/BrandData";
import "./checkboxButton.scss";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue as categoryUpdateValue} from "../../../../ActionCreators/CategoryData";

interface ExternalProps {
    path: string,
    label: string,
    color?: PropTypes.Color,
    dataType?: "Product" | "Brand" | "Category",
    className?: string,
    disabled?: boolean
}

interface StateProps {
    value: any,
    label: string,
    color?: PropTypes.Color,
    className?: string,
    disabled?: boolean
}

interface DispatchProps {
    onChange: (value: any) => void
}

type CheckboxButtonProps = StateProps & DispatchProps;

const CheckboxButton: React.FC<CheckboxButtonProps> = ({value, onChange, label, color, className, disabled}) => {
    return <Button
        variant="contained"
        className={"checkbox-button " + className}
        color={value ? (color || "primary") : undefined}
        disabled={disabled}
        size={"small"}
        onClick={() => onChange(!value)}
    >
        <>
            <div className={"checkbox-blank-square"}/>
            <CheckBoxOutlineBlankIcon className={"check-outline"}/>
            {value && <CheckIcon className={"check"}/>}
            {label}</>
    </Button>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: (() => {
        switch (externalProps.dataType) {
            case "Brand": return _.get(state.BrandData.brandData, externalProps.path, "");
            case "Category": return _.get(state.CategoryData.categoryData, externalProps.path, "");
            default: return _.get(state.ProductData.productData, externalProps.path, "");
        }
    })(),
    ...externalProps
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch((() => {
        switch (externalProps.dataType) {
            case "Brand": return brandUpdateValue
            case "Category": return categoryUpdateValue
            default: return productUpdateValue
        }
    })()(externalProps.path, value))
});

export const CheckboxButtonNotConnected = CheckboxButton;
export default connect(mapStateToProps, mapDispatchToProps)(CheckboxButton)
