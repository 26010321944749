import {Category} from "mesmetric-v2-common/models";

export interface CategoryDataState {
    categoryData?: Category,
    fetchedCategoryData?: Category,
    saveInProgress: boolean,
    changesDetected: boolean
}

export const initialState: CategoryDataState = {
    saveInProgress: false,
    changesDetected: false
};
