import React, {useEffect, useState} from "react";
import CheckboxButton from "../../ProductView/Common/CheckboxButton/CheckboxButton";
import DeleteItem from "../../../Components/DeleteItem/DeleteItem";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../Store";
import {Action} from "redux";
import {deleteBrand, isBrandRemovable} from "../../../ActionCreators/Brands";

type DispatchProps = {
    isRemovable: () => Promise<boolean>,
    deleteBrand: () => Promise<string | void>
}

const Controls: React.FC<{ className?: string } & DispatchProps> = (props) => {

    const [isRemovable, setIsRemovable] = useState(false);

    useEffect(() => {
        (async () => {
            const removable = await props.isRemovable();
            setIsRemovable(removable);
        })()
    }, [props]);

    return <div className={props.className}>
        <CheckboxButton
            path={"isActive"}
            label={"Aktywna"}
            dataType={"Brand"}
        />
        {isRemovable &&
        <DeleteItem size={"small"} onConfirm={async () => {
            await props.deleteBrand();
            window.history.back();
        }}/>}
    </div>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    isRemovable: () => dispatch(isBrandRemovable()),
    deleteBrand: () => dispatch(deleteBrand())
});

export default connect(null, mapDispatchToProps)(Controls);
