import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {Notification} from "../../State/Notifications";
import {Alert} from "@material-ui/lab";
import styles from "./Notifications.module.scss";
import {removeNotification} from "../../ActionCreators/Notifications";

interface StateProps {
    notifications: Notification[]
}

interface DispatchProps {
    removeNotification: (notification: Notification) => void
}

type Props = StateProps & DispatchProps;

class Notifications extends Component<Props> {
    public render = (): JSX.Element =>
        <div className={styles.notifications}>
            {this.props.notifications.map((notification, index) =>
                <Alert key={index}
                       onClose={() => this.props.removeNotification(notification)}
                       severity={notification.type}
                       className={styles.notification}>{notification.message}</Alert>)}
        </div>;
}

const mapStateToProps = (state: AppState): StateProps => ({
    notifications: state.Notifications.notifications
});

const mapDispatchToProps: DispatchProps = {
    removeNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);