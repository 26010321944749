import React, {useState} from "react";
import {connect} from "react-redux";
import "./photo.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {AppState} from "../../../../Store";
import {BrandPhotos} from "../../FilesUpload/FilesUpload";
import {Photo} from "mesmetric-v2-common/models";
import Square from "../../../ProductView/ProductDetails/FilesUploaded/Square/Square";
import {getCroppedSrc} from "../../../../Utils/PhotoUtils";
import {updateValue} from "../../../../ActionCreators/BrandData";
import Button from "@material-ui/core/Button";
import AspectRatio from "@material-ui/icons/AspectRatio";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import CropDinIcon from '@material-ui/icons/CropDin';
import Crop169Icon from '@material-ui/icons/Crop169';
import PhotoCrop, {ASPECT} from "../../../ProductView/ProductDetails/FilesUploaded/Photos/PhotoCrop/PhotoCrop";
import BlockIcon from '@material-ui/icons/Block';
import ClearIcon from '@material-ui/icons/Clear';

interface StateProps {
    photos?: BrandPhotos
}

interface DispatchProps {
    onChange: (value?: BrandPhotos) => void
}

type PhotosProps = StateProps & DispatchProps;

const PHOTO_KEYS: { icon: JSX.Element, type: keyof BrandPhotos, title: string, aspect: ASPECT }[] = [
    {
        type: "fullScreen",
        icon: <>
            <AspectRatio fontSize={"small"}/>
        </>,
        title: "Pełny ekran",
        aspect: "free"
    },
    {
        type: "rectangle",
        icon: <>
            <Crop169Icon fontSize={"small"}/>
        </>,
        title: "Prostokąt",
        aspect: "16:10"
    },
    {
        type: "square",
        icon: <>
            <CropDinIcon fontSize={"small"}/>
        </>,
        title: "Kwadrat",
        aspect: "1:1"
    }];

interface PhotosState {
    croppedPhoto?: Photo,
    photoCropModalOpen: boolean,
    aspectName: ASPECT,
    photoType?: keyof BrandPhotos
}

const Photos: React.FC<PhotosProps> = props => {
    const [state, setState] = useState<PhotosState>({photoCropModalOpen: false, aspectName: "free"});
    const photos = props.photos;

    return <>
        {state.photoCropModalOpen &&
        state.croppedPhoto &&
        <PhotoCrop
            photo={state.croppedPhoto}
            aspectName={state.aspectName}
            turnOffGuidelines
            onCancel={() => setState({photoCropModalOpen: false, aspectName: "free"})}
            onChange={(photo) => {
                if (state.photoType) {
                    const photos = {
                        ...props.photos,
                        [state.photoType]: photo
                    }
                    props.onChange(photos);
                    setState({photoCropModalOpen: false, aspectName: "free"})
                }
            }}
        />}
        {photos && !!Object.keys(photos).length && <div className={"row brand-photo-preview"}>
            <div className={"col-8"}>
                <Square
                    style={{backgroundImage: `url(${photos.fullScreen?.srcResolved})`}}>
                </Square>
            </div>
            <div className={"col-4"}>
                <div className={"buttons"}>
                    <Button
                        className={"button-with-icon"}
                        size="small"
                        href={photos.fullScreen?.srcResolved || ""}
                        target={"_blank"}
                        title={"Pobierz"}
                        variant="contained">
                        <CloudDownloadOutlined/>
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        title={"Usuń"}
                        onClick={() => props.onChange({})}
                    ><DeleteIcon/>
                    </Button>
                </div>
            </div>
        </div>}


        {photos && (PHOTO_KEYS.map(el => photos[el.type]).filter(v => v !== undefined) as Photo[]).map((photo, index) => {
                return <div className={"row brand-photo-preview"} key={index}>
                    <div className={"col-8"}>
                        <Square
                            style={photo.src && photo.crops.length ? {backgroundImage: `url(${getCroppedSrc(photo, {w: 132})})`} : undefined}>
                            {photo.src && photo.crops.length === 0 && <BlockIcon/>}
                        </Square>
                    </div>
                    <div className={"col-4"}>
                        <div className={"buttons"}>
                            <Button
                                disabled={!photo.src}
                                size="small"
                                variant="contained"
                                title={PHOTO_KEYS[index].title}
                                className={"button-with-icon"}
                                onClick={() => {
                                    if (!photo.src) {
                                        return;
                                    }
                                    setState({
                                        croppedPhoto: photo,
                                        photoCropModalOpen: true,
                                        aspectName: PHOTO_KEYS[index].aspect,
                                        photoType: PHOTO_KEYS[index].type
                                    })
                                }}
                            >
                                {PHOTO_KEYS[index].icon}
                            </Button>
                            <Button
                                disabled={!photo.src}
                                size="small"
                                variant="contained"
                                title={"Wyczyść"}
                                className={"button-with-icon"}
                                onClick={() => {
                                    const photo = photos[PHOTO_KEYS[index].type];
                                    if (photo) {
                                        photo.crops = [];
                                        const updatedPhotos = {
                                            ...photos,
                                            [PHOTO_KEYS[index].type]: photo
                                        }
                                        props.onChange(updatedPhotos);
                                    }
                                }}
                            >
                                <ClearIcon fontSize={"small"}/>
                            </Button>
                        </div>
                    </div>
                </div>
            }
        )}
    </>
}

const mapStateToProps = (state: AppState): StateProps => ({
    photos: state.BrandData.brandData?.photos
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    onChange: (value) => dispatch(updateValue("photos", value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
