import {applyMiddleware, combineReducers, compose, createStore} from 'redux'

import * as Reducers from "./Reducers"

import thunk from 'redux-thunk';

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers(
    {
        Categories: Reducers.Categories,
        Brands: Reducers.Brands,
        BrandData: Reducers.BrandData,
        CategoryData: Reducers.CategoryData,
        ProductData: Reducers.ProductData,
        User: Reducers.User,
        Notifications: Reducers.Notifications,
        Products: Reducers.Products,
        ProductsFilters: Reducers.ProductsFilters,
        Designers: Reducers.Designers,
        ProductElements: Reducers.ProductElements
    });
export const store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(thunk)));
export default store
export type AppState = ReturnType<typeof rootReducer>
